<template>
  <v-container class="mt-3 ml-3 mr-3 white fill-height v-align-start font-inter">
    <v-row align-content="center" justify="center">
      <v-col cols="12" sm="12" class="back-btn-container card">
        <div class="back-btn">
          <router-link :to="{ name: 'Cases', params: { handle: $route.params.handle, key: $route.params.key } }">
            <v-icon>mdi-chevron-left</v-icon> {{ $t('backToTestCases') }}
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="case-contents">
        <div class="item-area mb-5" v-if="!isEditMode">
          <h2>{{ $t('createNewTestCase') }}</h2>
        </div>
        <div class="item-area mb-5" v-else>
          <h2>{{ $t('editTestCase') }}</h2>
        </div>
        <div class="item-area ml-10">
          <h5>{{ $t('template') }}</h5>
        </div>
        <div class="item-area mb-5">
          <icon-dots />
          <v-select v-model="templateId" class="pl-3 ml-5 bg-color-grey round-8" :items="templates" item-text="name"
            item-value="uid" dense height="38px" placeholder="Text" @change="handleTemplateChange" />
        </div>
        <div class="item-area ml-10">
          <h5 style="width: 55%; display: flex; justify-content: start">{{ $t('title') }}</h5>
          <h5>{{ $t('id') }}</h5>
        </div>
        <div class="item-area mb-5">
          <icon-dots />
          <v-text-field placeholder="Title" v-model="caseName" class="bg-color-grey round-8 ml-5 pl-3 w-60" dense
            height="38px" />
          <v-text-field placeholder="HM-1" v-model="caseID" class="bg-color-grey round-8 ml-5 pl-3 w-40" dense
            height="38px" />
        </div>
        <div class="item-area ml-10">
          <h5>{{ $t('folder') }}</h5>
        </div>
        <div class="item-area mb-5">
          <icon-dots />
          <v-select v-model="selectedFolderUID" :items="folders" item-text="name" item-value="uid"
            class="pl-3 ml-5 bg-color-grey round-8" height="38px" dense />
        </div>
        <div class="item-area ml-10">
          <h5>{{ $t('priority') }}</h5>
        </div>
        <div class="item-area mb-5 position-relative">
          <icon-dots />
          <v-select v-model="casePriority" :items="priorities" item-text="text" item-value="value"
            class="pl-3 ml-5 bg-color-grey round-8" placeholder="Priority" dense height="38px" />
        </div>
        <div class="item-area ml-10">
          <h5>{{ $t('tags') }}</h5>
        </div>
        <div class="item-area mb-5 position-relative">
          <icon-dots />
          <v-select v-model="caseTags" class="ml-5 pl-3 bg-color-grey round-8" placeholder="Tags" :items="tags"
            item-value="value" item-text="text" dense height="38px" />
        </div>
        <div class="item-area ml-10">
          <h5>{{ $t('addAttachment') }}</h5>
        </div>
        <div class="item-area position-relative">
          <icon-dots />
          <v-file-input class="ml-5 pl-3 bg-color-grey round-8" dense height="38px" />
        </div>

        <!-- custom fields from template -->
        <CustomFieldFromTemplate :key="field.id" :field="field" v-for="field in customFieldsFromTemplate" />
      </v-col>

      <!-- step -->
      <v-col cols="12" sm="12" class="pl-0 pr-0 block" id="normal_step">
        <step-editor v-for="(item, index) in steps" :key="item.id" :isSharedStep="false" :caseStep="item"
          :title="`Step ` + (index + 1)" :step-index="index" @delete-item="deleteStep" @duplicate-step="duplicateStep"
          @create-shared-step="handleCreate" @description-change="changeDesctiption" @result-change="changeResult"
          @children-change="changeChildren"></step-editor>
      </v-col>
      <!-- shared step -->

      <v-col cols="12" sm="12" class="pl-0 pr-0 block" id="shared_step">
        <step-editor v-for="(item, index) in sharedSteps" :key="index" :index="item.id" :prop-title="item.title"
          :prop-description="item.description" :prop-result="item.expectedResult" :caseStep="item" :title="item.title"
          :prop-children="item.children" :isChildStep="false" :isSharedStep="true" @delete-item="deleteStep"
          :step-index="index"
          @duplicate-step="duplicateStep" @create-shared-step="handleCreate" @description-change="changeDesctiption"
          @result-change="changeResult" @children-change="changeChildren" />
      </v-col>
      <v-col cols="12" sm="12">
        <div class="flex justify-center">
          <template>
            <div class="text-center">
              <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="text-capitalize mr-3 pl-7 pr-7 round-8" depressed v-bind="attrs" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                    {{ $t('addSharedStep') }}
                  </v-btn>
                </template>
                <v-card>
                  <template>
                    <v-container>
                      <v-text-field v-model="searchTerm" class="round-8" placeholder="Search by name" dense
                        height="40px" prepend-inner-icon="mdi-magnify" filled single-line></v-text-field>
                      <v-radio-group v-model="selectedItem">
                        <v-radio v-for="(item, index) in filteredSelectedItems" :label="item.name" :value="item"
                          :key="index"></v-radio>
                      </v-radio-group>
                    </v-container>
                  </template>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="text-capitalize mr-3 pl-7 pr-7 round-8" depressed @click="menu = false">
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn class="text-capitalize mr-3 pl-7 pr-7 round-8 f-color-white" depressed color="#061AAE"
                      @click="addSharedStep">
                      {{ $t('add') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
          </template>
          <v-btn class="text-capitalize f-color-white ml-3 pl-7 pr-7 round-8" color="#061AAE" depressed
            @click="addStep">
            <v-icon>mdi-plus</v-icon>
            {{ $t('addStep') }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="flex justify-end pr-8">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed color="#F2F4F7" class="text-capitalize pl-6 pr-6" v-bind="attrs" v-on="on">
              {{ $t('actions') }}
              <v-icon size="18px">mdi-chevron-up</v-icon>
            </v-btn>
          </template>
          <v-card rounded="8" elevation="0">
            <v-list>
              <v-list-item :key="1">
                <v-list-item-title>
                  <v-icon>mdi-content-duplicate</v-icon>
                  {{ $t('duplicate') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :key="2" @click="handleCreate">
                <v-list-item-title>
                  <v-icon>mdi-stairs-box</v-icon>
                  {{ $t('createSharedStep') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :key="3">
                <v-list-item-title>
                  <v-icon>mdi-arrow-down-right</v-icon>
                  {{ $t('addChildStep') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item :key="4">
                <v-list-item-title class="color-red">
                  <v-icon class="f-color-red">mdi-trash-can-outline</v-icon>
                  {{ $t('remove') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-btn color="#061AAE" v-if="isEditMode" depressed class="f-color-white ml-2 text-capitalize pl-6 pr-6"
          @click="saveTestCase">{{ $t('save') }}</v-btn>
        <v-btn color="#061AAE" v-else depressed :disabled="isCreateDisabled"
          class="f-color-white ml-2 text-capitalize pl-6 pr-6" @click="createTestCase">{{ $t('create') }}</v-btn>
      </v-col>
      <shared-step-dialog :isOpen="isOpenSharedStepDialog" @closeDialog="handleClose" />
    </v-row>
  </v-container>
</template>
<script>
import CustomFieldFromTemplate from '@/views/Tests/Case/Components/CustomFieldFromTemplate.vue';
import StepEditor from '@/views/Tests/Case/Components/StepEditor.vue';
import SharedStepDialog from '@/views/Tests/Case/Components/CreateSharedStep.vue';
import iconDots from '@/assets/svg/dots-20x20-gray.svg';
import { priorities } from '@/constants/grid.js';

import makeFoldersService from '@/services/api/folder';
import makeCasesService from '@/services/api/case';
import makeSharedStepService from '@/services/api/sharedStep';
import makeTemplateService from '@/services/api/template';

import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('user');

let caseService;
let sharedStepService;
let templateService;

export default {
  components: {
    CustomFieldFromTemplate,
    StepEditor,
    SharedStepDialog,
    iconDots,
  },
  computed: {
    ...mapState(['currentAccount']),
    filteredSelectedItems() {
      if (!this.searchTerm) {
        return this.selectedItems;
      }
      return this.selectedItems.filter((item) => item.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
    stepsLength() {
      return this.steps.length;
    }
  },
  data() {
    return {
      isCreateDisabled: false,
      isEditMode: false,
      caseName: '',
      caseID: '',
      templateId: '',
      casePriority: '',
      caseTags: '',
      folders: [],
      selectedFolderUID: '',
      editItem: {},
      isOpenSharedStepDialog: false,
      menu: false,
      steps: [],
      stepCount: 0,
      customFieldsFromTemplate: [],
      sharedSteps: [],
      sharedStepCount: 0,
      selectedItems: [],
      selectedItem: null,
      searchTerm: '',
      templates: [],
      tags: [],
      priorities: Object.values(priorities),
    };
  },
  async created() {
    caseService = makeCasesService(this.$api);
    templateService = makeTemplateService(this.$api);

    sharedStepService = makeSharedStepService(this.$api);
    if (this.$route.params.uid) {
      // TODO - pull from state when possible to avoid extra API calls
      //this.editItem = this.$store.state...;
      this.isEditMode = true;
      const response = await caseService.getCase(
        this.$store.state.user.currentAccount.handle,
        this.$route.params.key,
        this.$route.params.uid
      );
      if (response.status == 200) {
        this.editItem = response.data;
      } else {
        console.error('Error occured in backend!');
      }
      this.inputEditValues();
    }
  },
  async mounted() {
    await this.getProjectFolders();
    this.selectedFolderUID = this.$store.state.folder.selectedFolderUID || '';
    this.fetchSharedSteps();

    const response = await templateService.getTemplates(
      this.$route.params.handle,
      this.$route.params.key,
      'per_page=9999&current_page=1'
    );
    this.templates = response.data.templates;
    this.getCustomFieldsFromTemplate(this.templateId);
  },

  methods: {
    async getProjectFolders() {
      const folderService = makeFoldersService(this.$api);
      try {
        const {
          data: { folders },
        } = await folderService.getProjectFolders(this.$route.params.handle, this.$route.params.key);
        this.folders = this.findLeafNodes(folders);
      } catch (error) {
        console.error(error);
      }
    },
    findLeafNodes(folders) {
      let nodes = [];
      for (let idx = 0; idx < folders.length; idx++) {
        let parent = {
          name: folders[idx].name,
          uid: folders[idx].uid,
        };
        if (!folders[idx].children || folders[idx].children.length < 1) {
          nodes.push(parent);
        } else {
          nodes.push(parent, ...this.findLeafNodes(folders[idx].children));
        }
      }
      return nodes;
    },
    inputEditValues() {
      this.caseName = this.editItem.name;
      this.casePriority = this.editItem.customFields.priority;
      this.caseTags = this.editItem.customFields.tags;
      this.caseID = this.editItem.externalId;
      this.steps = this.editItem.steps;
      this.sharedSteps = this.editItem.customFields.sharedSteps;
      this.templateId = this.editItem.testTemplateUid;
    },
    handleCreate() {
      this.isOpenSharedStepDialog = true;
    },
    handleClose() {
      this.isOpenSharedStepDialog = false;
    },
    addStep() {
      let key = this.stepsLength + 1;

      let step = {
        id: key,
        description: '',
        children: [],
        expectedResult: '',
      };

      this.steps.push(step);
      this.stepUniqueId++;
    },
    addSharedStep() {
      if (this.selectedItem !== null) {
        let selectedSharedStep = this.selectedItem.steps;
        let key = this.sharedStepCount;
        this.sharedStepCount++;

        this.sharedSteps.push(...selectedSharedStep);
        this.menu = false;
        this.searchTerm = '';
        this.selectedItem = null;
      }
    },
    deleteStep(index, isShared) {
      if (isShared == true) {
        this.sharedSteps.splice(index, 1);
      } else {
        this.steps.splice(index, 1);
      }
    },
    duplicateStep(isShared) {
      if (isShared) {
        this.addSharedStep();
      } else {
        this.addStep();
      }
    },
    changeResult(result, stepId, isSharedStep) {
      let step = this.getStepFromId(stepId, isSharedStep);
      if (isSharedStep == true) {
        this.sharedSteps[step].expectedResult = result;
      } else {
        this.steps[step].expectedResult = result;
      }
    },
    changeDesctiption(description, stepId, isSharedStep) {
      let step = this.getStepFromId(stepId, isSharedStep);
      if (isSharedStep == true) {
        this.sharedSteps[step].description = description;
      } else {
        this.steps[step].description = description;
      }
    },
    changeChildren(children, stepIndex, isSharedStep) {
      if (isSharedStep == true) {
        this.sharedSteps[stepIndex].children = children;
      } else {
        this.steps[stepIndex].children = children;
      }
    },
    getStepFromId(stepId, isSharedStep) {
      let steps = isSharedStep ? this.sharedSteps : this.steps;
      for (let step in steps) {
        if (steps[step].id == stepId) {
          return step;
        }
      }
    },
    // create test case funtion
    async createTestCase() {
      this.isCreateDisabled = true;
      const handle = this.currentAccount.handle;
      const steps = this.steps.map((item, index) => {
        item.title = `Step ${index + 1}`;
        item.children.map((child, index) => {
          return (child.title = item.title + `.${index + 1}`);
        });
        return item;
      });

      let data = {
        name: this.caseName,
        externalId: this.caseID,
        source: 'testfiesta',
        projectKey: this.$route.params.key,
        parentId: this.selectedFolderUID,
        templateId: this.templateId,
        customFields: {
          tags: this.caseTags,
          priority: this.casePriority,
          sharedSteps: this.sharedSteps,
          templateFields: this.customFieldsFromTemplate,
        },
        steps,
      };
      try {
        const response = await caseService.createTestCase(handle, this.$route.params.key, data);
        if (response.status == 200) {
          if (this.selectedFolderUID) {
            this.$router.push({
              name: 'CasesFolder',
              params: {
                folderUid: this.selectedFolderUID,
              },
            });
          } else {
            this.$router.push({ name: 'Cases' });
          }
          this.getProjectFolders();
        } else {
          console.error(response.error);
        }
      } catch (err) {
        console.error('Error :', err);
        this.isCreateDisabled = false;
      }
    },
    // save updated test case function
    async saveTestCase() {
      const handle = this.currentAccount.handle;
      const payload = {
        name: this.caseName,
        externalId: this.caseID,
        projectKey: this.$route.params.key,
        templateId: this.templateId,
        parentId: this.selectedFolderUID,
        source: 'testfiesta',
        customFields: {
          tags: this.caseTags,
          priority: this.casePriority,
          sharedSteps: this.sharedSteps,
          templateFields: this.customFieldsFromTemplate,
        },
        steps: this.steps,
      };

      try {
        const response = await caseService.updateTestCase(
          handle,
          this.$route.params.key,
          this.editItem.testCaseRef,
          payload
        );
        if (response.status == 200) {
          if (this.selectedFolderUID) {
            this.$router.push({
              name: 'CasesFolder',
              params: {
                folderUid: this.selectedFolderUID,
              },
            });
          } else {
            this.$router.push({ name: 'Cases' });
          }
        } else {
          console.error('Error occured in backend!');
        }
      } catch (err) {
        console.error('Error: ', err);
      }
    },
    async fetchSharedSteps() {
      try {
        const response = await sharedStepService.getSharedSteps(this.currentAccount.handle, this.$route.params.key);
        if (response.status === 200) {
          this.selectedItems = response.data.sharedSteps;
        }
      } catch (err) {
        console.error('Error fetching shared steps:', err);
      }
    },
    getCustomFieldsFromTemplate(templateId) {
      const selectedTemplate = this.templates.find((template) => template.uid === templateId);
      if (this.isEditMode && selectedTemplate) {
        this.customFieldsFromTemplate = this.editItem.testTemplateUid === templateId ? this.editItem.customFields.templateFields : selectedTemplate.customFields;
      } else {
        if (!selectedTemplate) {
          this.customFieldsFromTemplate = [];
        } else {
          this.customFieldsFromTemplate = selectedTemplate.customFields;
        }
      }
    },
    handleTemplateChange(value) {
      this.getCustomFieldsFromTemplate(value);
    },
  },
};
</script>
<style>
.justify-center {
  justify-content: center;
}

.property {
  min-width: 35px !important;
  background-color: white !important;
}

.f-color-white {
  color: white !important;
}

.f-color-red {
  color: #f2284e !important;
}

.justify-end {
  justify-content: end;
}

.align-start {
  text-align: start;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.step-header {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

.step-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.position-relative {
  position: relative;
}

.trash-style {
  position: absolute !important;
  right: -35px;
  top: 13px;
  color: #f2284e !important;
}

.item-area {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.v-align-start {
  align-items: flex-start !important;
}

.back-btn-container {
  display: flex;
  align-items: flex-start;
}

.back-btn a {
  text-decoration: none;
  color: #0c2ff3 !important;
  font-weight: 600;
}

.back-btn a i {
  color: #0c2ff3 !important;
}

.case-contents {
  display: block;
  max-width: 438px;
  width: 438px;
}

.bg-color-grey {
  background-color: #F9F9FB;
}

.v-text-field__details {
  display: none;
}

.round-8 {
  border-radius: 8px;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.v-text-field__slot textarea {
  padding: 12px;
}

.font-inter {
  font-family: Inter;
}

.v-list-item:hover {
  background-color: #f9fafb;
  cursor: pointer;
}

.v-menu__content {
  text-align: left !important;
}

.color-red {
  color: #f2284e;
}
</style>

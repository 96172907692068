<template>
  <div>
    <v-divider class="divider-custom"></v-divider>
    <div class="step-container mt-3">
      <div class="case-contents pl-4 pr-4">
        <div class="step-header mb-4">
          <!-- left side -->
          <div class="flex align-center justify-start">
            <input type="checkbox" style="border: none; background-color: #eaecf0" />
            <icon-dots />
            <h4 class="ml-1">{{ title }}</h4>
          </div>
          <!-- right side -->
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed rounded class="property" v-bind="attrs" v-on="on">
                <v-icon size="20px">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-card rounded="8" elevation="0">
              <v-list>
                <v-list-item :key="1" @click="$emit('duplicate-step', false)">
                  <v-list-item-title>
                    <v-icon>mdi-content-duplicate</v-icon>
                    {{ $t('duplicate') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item :key="2" @click="$emit('create-shared-step', false)">
                  <v-list-item-title>
                    <v-icon>mdi-stairs-box</v-icon>
                    {{ $t('createSharedStep') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item :key="3" @click="createChildStep">
                  <v-list-item-title>
                    <v-icon>mdi-arrow-down-right</v-icon>
                    {{ $t('addChildStep') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item :key="4" @click="$emit('delete-item', stepIndex, localIsSharedStep)">
                  <v-list-item-title class="color-red">
                    <v-icon class="f-color-red">mdi-trash-can-outline</v-icon>
                    {{ $t('remove') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
        <div class="ml-5 block">
          <h5 class="align-start">{{ $t('stepDescription') }}</h5>
          <v-textarea
            v-model="description"
            auto-grow
            :placeholder="$t('stepDescription')"
            background-color="#F9F9FB"
            class="round-8 mt-1 mb-2 pt-0"
            @input="$emit('description-change', description, caseStep.id, isSharedStep)"
          />
          <h5 class="align-start">{{ $t('expectedResult') }}</h5>
          <v-textarea
            v-model="expectedResult"
            auto-grow
            :placeholder="$t('expectedResult')"
            background-color="#F9F9FB"
            class="round-8 mt-1 mb-2 pt-0"
            @input="$emit('result-change', expectedResult, caseStep.id, isSharedStep)"
          />
          <!-- child steps area -->
          <div>
            <child-step
              v-for="(item, index) in children"
              :key="item.id"
              :parentTitle="title"
              :step-index="index"
              :childStep="item"
              @description-change-child="changeDescription"
              @result-change-child="changeResult"
              @delete-child="removeChildStep"
              @duplicate-child="createChildStep"
            ></child-step>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import iconDots from '@/assets/svg/dots-20x20-gray.svg';
import ChildStep from './ChildStep.vue';

export default {
  components: {
    ChildStep,
    iconDots,
  },
  data() {
    return {
      children: [],
      stepCount: 0,
      description: '',
      expectedResult: '',
      localIsSharedStep: false, // New local data property
    };
  },
  props: ['caseStep', 'isSharedStep', 'title', 'stepIndex'],
  methods: {
    createChildStep() {
      let key = this.stepCount + 1;
      this.stepCount++;
      let title = 'Child ' + ((this.children?.length ?? 0) + 1);
      let newItem = {
        id: key,
        description: '',
        expectedResult: '',
        title,
      };
      this.childUniqueId++;
      if (!this.children) {
        this.children = [];
      }
      this.children.push(newItem);
    },
    removeChildStep(index) {
      this.children.splice(index, 1);
    },
    changeResult(result, index) {
      this.children[index].expectedResult = result;
      this.$emit('children-change', this.children, this.stepIndex, this.localIsSharedStep);
    },
    changeDescription(description, index) {
      this.children[index].description = description;
      this.$emit('children-change', this.children, this.stepIndex, this.localIsSharedStep);
    },
  },
  created() {
    this.description = this.caseStep.description;
    this.expectedResult = this.caseStep.expectedResult;
    this.children = this.caseStep.children;
    this.localIsSharedStep = this.isSharedStep; // Initialize with prop value
  },
};
</script>
<style>
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.divider-custom {
  border-color: #EAECF0 !important;
}
</style>

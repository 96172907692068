<template>
  <div>
    <v-dialog
      class="test-cases-filter-drawer"
      v-model="internalIsOpen"
      attach
      fullscreen
      max-width="400"
      @input="onDialogInput"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6 mb-4">
            <h2 class="black--text">{{ $t('createNewSharedStep') }}</h2>
            <v-btn
              icon
              @click="closeDialog"
            >
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>
          <div>
            <h3 class="select-title mt-4">{{ $t('name') }}</h3>
            <v-text-field filled class="round-8" placeholder="Log in" dense height="38px" />
            <h3 class="select-title mt-4">{{ $t('stepDescription') }}</h3>
            <v-textarea auto-grow placeholder="Step description" background-color="#F9F9FB" class="round-8 mt-1 mb-2 pt-0 h-100"/>
            <h3 class="select-title mt-4">{{ $t('expectedResult') }}</h3>
            <v-textarea auto-grow placeholder="Expected result" background-color="#F9F9FB" class="round-8 mt-1 mb-2 pt-0 h-100"/>
            <div class="block ml-11">
              <div>
                <div class="flex justify-between">
                  <div class="flex">
                    <template>
                      <icon-dots></icon-dots>
                    </template>
                    <h3>{{ $t('step') }}</h3>
                  </div>
                  <v-icon>mdi-dots-vertical</v-icon>
                </div>
                <div>
                  <h3 class="select-title mt-4">{{ $t('stepDescription') }}</h3>
                  <v-textarea auto-grow placeholder="Step description" background-color="#F9F9FB" class="round-8 mt-1 mb-2 pt-0 h-100"/>
                  <h3 class="select-title mt-4">{{ $t('expectedResult') }}</h3>
                  <v-textarea auto-grow placeholder="Expected result" background-color="#F9F9FB" class="round-8 mt-1 mb-2 pt-0 h-100"/>
                </div>
              </div>
              <div>
                <div class="flex justify-between">
                  <div class="flex">
                    <template>
                      <icon-dots></icon-dots>
                    </template>
                    <h3>Navigate to the Login Page </h3>
                  </div>
                  <v-icon>mdi-dots-vertical</v-icon>
                </div>
                <div>
                  <h3 class="select-title mt-4">{{ $t('stepDescription') }}</h3>
                  <v-textarea auto-grow placeholder="Step description" background-color="#F9F9FB" class="round-8 mt-1 mb-2 pt-0 h-100"/>
                  <h3 class="select-title mt-4">{{ $t('expectedResult') }}</h3>
                  <v-textarea auto-grow placeholder="Expected result" background-color="#F9F9FB" class="round-8 mt-1 mb-2 pt-0 h-100"/>
                </div>
              </div>
            </div>
          </div>
          <div class="actions-container d-flex justify-end mt-5">
            <v-btn width="10rem" color="gray-100" full-width class="mr-4 text-capitalize" elevation="0" @click="closeDialog" >{{ $t('cancel') }}</v-btn>
            <v-btn width="10rem" color="blue" dark full-width elevation="0" class="text-capitalize" >{{ $t('save') }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import iconDots from '@/assets/svg/dots-20x20-gray.svg';

export default {
  name: 'SharedStepDialog',
  components: {
    iconDots,
  },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      internalIsOpen: this.isOpen
    };
  },
  watch: {
    isOpen(newVal) {
      this.internalIsOpen = newVal;
    }
  },
  methods: {
    closeDialog() {
      this.internalIsOpen = false;
      this.$emit('closeDialog');
    },
    onDialogInput(newVal) {
      if (!newVal) {
        this.$emit('closeDialog');
      }
    }
  }
};
</script>
<style>
.test-cases-filter-drawer .v-sheet {
  display: flex
}
.test-cases-filter-drawer .v-card__text {
  display: flex;
  flex-direction: column;
}
.test-cases-filter-drawer .actions-container {
  margin-top: auto !important;
}
.select-title{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
.flex{
  display: flex;
}
.justify-between{
  justify-content: space-between;
}
.h-100 .v-text-field__slot{
  height: 100px;
}
</style>
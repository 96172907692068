<template>
  <div class="mt-5">
    <div class="item-area ml-10">
      <h5>{{ field.name }}</h5>
    </div>
    <div :key="field.uid" class="item-area position-relative">
      <icon-dots />
      <v-textarea v-model="field.value" v-if="field.dataType === 'text'" :placeholder="field.name" class="bg-color-grey round-8 ml-5" auto-grow />
    </div>
  </div>
</template>

<script>
import iconDots from '@/assets/svg/dots-20x20-gray.svg';

export default {
  components: {
    iconDots,
  },
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style>
.item-area {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.case-contents {
  display: block;
  max-width: 438px;
  width: 438px;
}
</style>

<template>
  <div class="ml-11">
    <div class="flex justify-between">
      <div class="flex align-center">
        <template>
          <icon-dots></icon-dots>
        </template>
        <h4 class="ml-1">{{ parentTitle + "." + (stepIndex+1) }}</h4>
      </div>
      <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed rounded class="property" v-bind="attrs" v-on="on" >
                <v-icon size="20px">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-card rounded="8" elevation="0">
              <v-list>
                <v-list-item :key="1" @click="$emit('duplicate-child')">
                  <v-list-item-title>
                    <v-icon>mdi-content-duplicate</v-icon>
                    {{ $t('duplicate') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item :key="2" @click="$emit('delete-child', stepIndex)">
                  <v-list-item-title class="color-red">
                    <v-icon class="f-color-red">mdi-trash-can-outline</v-icon>
                    {{ $t('remove') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
    </div>
    <div>
      <h3 class="select-title mt-4">{{ $t('stepDescription') }}</h3>

      <v-textarea auto-grow placeholder="Step description" background-color="#F9F9FB" class="round-8 mt-1 mb-2 pt-0"
        v-model="description" @input="$emit('description-change-child', description, stepIndex)"
      />

      <h3 class="select-title mt-4">{{ $t('expectedResult') }}</h3>
      <v-textarea auto-grow placeholder="Expected result" background-color="#F9F9FB" class="round-8 mt-1 mb-2 pt-0"
        v-model="expectedResult" @input="$emit('result-change-child', expectedResult, stepIndex)"
      />
    </div>
  </div>
</template>
<script>
import iconDots from '@/assets/svg/dots-20x20-gray.svg';

export default {
  name: 'ChildStep',
  data() {
    return {
      name: "",
      description: "",
      this: "",
      expectedResult: "",
    }
  },
  components: {
    iconDots,
  },
  props: {
    childStep: Object,
    parentTitle: String,
    stepIndex: Number,
  },
  created() {
    this.description = this.childStep.description;
    this.expectedResult = this.childStep.expectedResult;
  },
}
</script>
